import { FunctionComponent } from "react";
import styles from "../styles/MainPage.module.css";

const MainPage: FunctionComponent = () => {

  return (
    <div className={styles.mainPage}>
      <div className={styles.wrapper}>
        <div className={styles.linkedInCard}>
          <div className={styles.card} />
          <b className={styles.linkedInTitle}>
            <span>Check out my LinkedIn <a href="https://www.linkedin.com/in/roger-j-luo/">here...</a></span>
          </b>
        </div>
        <div className={styles.resumeCard1}>
          <div className={styles.card1} />
          <b className={styles.resumeTitle1}>
            <span>Check out my resume <a href="/resume">here...</a></span>
          </b>
        </div>
        <div className={styles.titleCard}>
          <div className={styles.card2} />
          <b className={styles.resumeTitle2}>
            <p className={styles.myNameIs}>My name is</p>
            <p className={styles.myNameIs}>Roger Luo</p>
          </b>
          <div className={styles.xl}>Welcome to my website!</div>
          <div className={styles.xl1}>
            I’m a software engineer with a passion in product and
            entrepreneurship.
          </div>
          <div className={styles.image}>
            <img className={styles.imageIcon} alt="" src="/image1@2x.png" />
          </div>
        </div>
        <div className={styles.photosCard}>
          <div className={styles.card3} />
          <b className={styles.title}>I also take photos</b>
          <div className={styles.xl2}>
            <span>{`I’m currently working on a travel & photography blog to be added to this site! In the meanwhile take a look at my `}</span>
            <span className={styles.here}><a href="https://www.flickr.com/photos/197401385@N04/">flikr</a></span>
            <span>{`. `}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
