import { FunctionComponent } from "react";
import styles from "../styles/ResumePage.module.css";

const ResumePage: FunctionComponent = () => {
  return (
    <div className={styles.resumePage}>
      <div className={styles.wrapper}>
        <div className={styles.educationCard}>
          <div className={styles.card1} />
          <b className={styles.title1}>Education</b>
          <b className={styles.xl20}>Carnegie Mellon University</b>
          <div className={styles.xl22}>Aug 2021 - Dec 2022</div>
          <div className={styles.xl24}>
            <p className={styles.masterOfInformation}>
              Master of Information Systems Management
            </p>
            <p className={styles.masterOfInformation}>3.91 GPA</p>
          </div>
          <b className={styles.xl21}>University of Pennsylvania</b>
          <div className={styles.xl23}>Aug 2014 - May 2018</div>
          <div className={styles.xl25}>
            <p className={styles.masterOfInformation}>
              B.S.E in Computer Science
            </p>
            <p className={styles.masterOfInformation}>Minor in Economics</p>
            <p className={styles.masterOfInformation}>3.21 GPA</p>
          </div>
        </div>
        <div className={styles.jobsCard}>
          <div className={styles.card} />
          <b className={styles.title}>Work</b>
          <div className={styles.amazonGroup}>
            <b className={styles.xl15}>Amazon</b>
            <div className={styles.xl1}>
              <ul className={styles.productOwnerAndDeveloperOf}>
                <li className={styles.productOwnerAnd}>
                  Designed, implemented, and presented to stakeholders a user
                  interface for Alexa Echo Show devices developed with kotlin.
                </li>
                <li className={styles.productOwnerAnd}>
                  Provided store associates with setup information and network
                  disconnection alerts.
                </li>
                <li>
                  Resolved integration with existing background setup application
                  in android threading and package management
                </li>
              </ul>
            </div>
            <b className={styles.xl2}>Software Development Engineer Intern</b>
            <div className={styles.xl18}>Jun 2022 - Aug 2022</div>
            <div className={styles.location3}>
              <div className={styles.xl19}>Denver, CO</div>
              <img
                className={styles.iolocationoutlineIcon}
                alt=""
                src="/iolocationoutline5.svg"
              />
            </div>
          </div>
          <div className={styles.sbbGroup}>
            <b className={styles.xl}>SBB Research Group</b>
            <div className={styles.xl1}>
              <ul className={styles.productOwnerAndDeveloperOf}>
                <li className={styles.productOwnerAnd}>
                  Product owner and developer of an internal equity trading,
                  historical records, and position handling system implemented
                  with Java and Springboot. Increased system performance with new
                  logic flows and modules, decreasing trading and inventory
                  runtime by over 40% while serving analysts with additional
                  metrics.
                </li>
                <li className={styles.productOwnerAnd}>
                  Developed backend API for trading, data collection, and
                  reconciliation with 3rd party brokers in Java, allowing traders
                  to automate the execution of strategy-based trades and monitor
                  account statuses.
                </li>
                <li className={styles.productOwnerAnd}>
                  Restructured the existing MySQL database schema and rewrote
                  queries to fit the new system, reducing query time by up to 80%
                  while storing more data and increasing table organization.
                </li>
                <li className={styles.productOwnerAnd}>
                  Developed internal web pages for trading and human resources in
                  Typescript, React, Javascript, and PHP with a focus on user
                  experience, cutting down idle time and clicks for users.
                </li>
                <li className={styles.productOwnerAnd}>
                  Coordinated with stakeholders, created user stories, managed
                  sprints, and supported live services.
                </li>
                <li className={styles.productOwnerAnd}>
                  Supervised junior developers and interns to develop equity
                  trading and position handling functions.
                </li>
                <li>Promoted from Applications Developer in 2020.</li>
              </ul>
            </div>
            <b className={styles.xl2}>Software Engineer (Full Time)</b>
            <div className={styles.xl3}>Dec 2018 - May 2018</div>
            <div className={styles.location}>
              <div className={styles.xl4}>Northbrook, IL</div>
              <img
                className={styles.iolocationoutlineIcon}
                alt=""
                src="/iolocationoutline5.svg"
              />
            </div>
          </div>
          <div className={styles.eaGroup}>
            <b className={styles.xl5}>Electronic Arts</b>
            <div className={styles.xl6}>
              <ul className={styles.productOwnerAndDeveloperOf}>
                <li className={styles.productOwnerAnd}>
                  Designed, implemented, and presented to stakeholders a user
                  interface for Alexa Echo Show devices developed with kotlin.
                </li>
                <li className={styles.productOwnerAnd}>
                  Provided store associates with setup information and network
                  disconnection alerts.
                </li>
                <li>
                  Resolved integration with existing background setup application
                  in android threading and package management
                </li>
              </ul>
            </div>
            <b className={styles.xl7}>Software Development Engineer Intern</b>
            <div className={styles.xl8}>Jun 2022 - Aug 2022</div>
            <div className={styles.location1}>
              <div className={styles.xl9}>Austin, TX</div>
              <img
                className={styles.iolocationoutlineIcon1}
                alt=""
                src="/iolocationoutline5.svg"
              />
            </div>
          </div>
          <div className={styles.kiewitGroup}>
            <b className={styles.xl5}>Kiewit Corporation</b>
            <div className={styles.xl6}>
              <ul className={styles.productOwnerAndDeveloperOf}>
                <li className={styles.productOwnerAnd}>
                  Created and debugged web forms in javascript and ABAP
                </li>
                <li>Created data manipulation scripts in VBA</li>
              </ul>
            </div>
            <b className={styles.xl7}>Software Development Engineer Intern</b>
            <div className={styles.xl8}>Jun 2022 - Aug 2022</div>
            <div className={styles.location1}>
              <div className={styles.xl9}>Omaha, NE</div>
              <img
                className={styles.iolocationoutlineIcon1}
                alt=""
                src="/iolocationoutline5.svg"
              />
            </div>
          </div>
        </div>
        <div className={styles.sideGigsCard}>
          <div className={styles.card2} />
          <b className={styles.title2}>Side Gigs</b>
          <b className={styles.xl26}>Undercut Gaming</b>
          <div
            className={styles.xl27}
          >I collect and sell trading cards as a hobby and run my own storefront on the platform TCGPlayer. I have had a revenue of over $2000 on the site since 2020. </div>
        </div>
        <div className={styles.skillsCard}>
          <div className={styles.card3} />
          <b className={styles.title3}>Skills</b>
          <b className={styles.xl28}>Coding Languages</b>
          <div className={styles.xl29}>
            Java, Typescript, Javascript, Kotlin, React, Redux, Python, C, Golang
          </div>
          <div className={styles.xl30}>
            ScrumInc Registered Product Owner. Jan 2023
          </div>
          <b className={styles.xl31}>Data</b>
          <div className={styles.xl32}>
            SQL, MySQL, Microsoft PowerBI, Data Analytics, numPy, pandas,
            Scikit-learn, R, Firebase, Spark, dynamoDB
          </div>
          <b className={styles.xl33}>Additional Skills</b>
          <div className={styles.xl34}>
            Scrum, Agile, Asana, Jira, Figma, Gitlab, Docker, Android Studio, AWS
            EC2, AWS S3, AWS Amplify, Data Visualization, User Personas, Design
            Thinking, Machine Learning
          </div>
          <b className={styles.xl35}>Soft Skills</b>
          <div className={styles.xl36}>
            User Empathy, Negotiation, Flexible, Problem Solving, Strategy,
            Management
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResumePage;
